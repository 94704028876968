import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { isApiError } from '@digital-magic/react-common/lib/api'
import { hasValue } from '@digital-magic/ts-common-utils'
import { routes } from '@constants/routes'
import { RequestErrorHandler } from '@api/types'
import {
  CreateOptionRequest,
  OptionId,
  useDeleteOption,
  useGetOption,
  useUpdateOption
} from '@api/endpoints/buildings/options'
import { useAdminDefaultErrorHandler } from '@hooks/useAdminDefaultErrorHandler'
import { useSnackbar } from 'notistack'
import { ButtonWithConfirmation } from '@controls/Button/ButtonWithConfirmation'
import { useFormTyped } from '@controls/Form'
import { OptionForm } from './OptionForm'
import { OptionValuesForm } from './OptionValuesForm'
import { OptionFormStyled } from './styles'

//import { isDeletableEntity, isEditableEntity } from '@endpoints/utils'

type Props = Readonly<{ optionId: OptionId }>

export const EditOptionForm: React.FC<Props> = ({ optionId }) => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const defaultErrorHandler = useAdminDefaultErrorHandler()

  const onUpdateOptionSuccess = (): void => {
    void getOption.refetch()
  }

  const onDeleteOptionSuccess = (): void => {
    navigate(routes.Admin.Options)
  }

  const onUpdateOptionError: RequestErrorHandler = (e) => {
    if (isApiError(e)) {
      switch (e.code) {
        case 'AlreadyExistsError':
          snackbar.enqueueSnackbar(t('pages.admin.option.errors.already_exists'))
          break
        case 'ConstraintViolationError':
          snackbar.enqueueSnackbar(t('pages.admin.option.errors.update_constraint'))
          break
        default:
          defaultErrorHandler(e)
          break
      }
    } else {
      defaultErrorHandler(e)
    }
  }

  const onDeleteOptionError: RequestErrorHandler = (e) => {
    if (isApiError(e) && e.code === 'ConstraintViolationError') {
      snackbar.enqueueSnackbar(t('pages.admin.option.errors.delete_constraint'))
    } else {
      defaultErrorHandler(e)
    }
  }

  const getOption = useGetOption(optionId, {
    onError: defaultErrorHandler,
    enabled: hasValue(optionId)
  })

  const updateOption = useUpdateOption(optionId, {
    onError: onUpdateOptionError,
    onSuccess: onUpdateOptionSuccess
  })

  const deleteOption = useDeleteOption({ onError: onDeleteOptionError, onSuccess: onDeleteOptionSuccess })

  const isLoading = getOption.isFetching || updateOption.isLoading || deleteOption.isLoading

  const onSubmit = (values: CreateOptionRequest): void => {
    updateOption.mutate(values)
  }

  const form = useFormTyped({
    resolver: CreateOptionRequest,
    onSubmit,
    defaultValues: {
      multiValue: false
    }
  })

  React.useEffect(() => {
    if (getOption.data) {
      form.reset(getOption.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOption.data])

  const isOptionFormDisabled = React.useMemo(
    () => false, //hasValue(params.optionId) && !isEditableEntity(getOption.data?.allowedActions),
    [
      /*params.optionId, getOption.data*/
    ]
  )
  const isDeleteDisabled = React.useMemo(
    () => false, //hasValue(params.optionId) && !isDeletableEntity(getOption.data?.allowedActions),
    [
      /*params.optionId, getOption.data*/
    ]
  )

  const onDeleteOption =
    (optionId: OptionId) =>
    (confirmResult: boolean): void => {
      if (confirmResult) {
        deleteOption.mutate(optionId)
      }
    }

  return (
    <OptionFormStyled>
      <h1>{t('pages.admin.option.edit.title')}</h1>
      <OptionForm
        isOptionFormDisabled={isOptionFormDisabled}
        isDeleteDisabled={isDeleteDisabled}
        isLoading={isLoading}
        f={form}
        formButtons={
          <ButtonWithConfirmation
            color="error"
            disabled={isLoading || isDeleteDisabled}
            onConfirmResult={onDeleteOption(optionId)}
            confirmTitle={t('global.consent.delete.title')}
            confirmMessage={t('global.consent.delete.message')}
          >
            {t('global.buttons.delete')}
          </ButtonWithConfirmation>
        }
      />
      <OptionValuesForm
        optionId={optionId}
        isParentLoading={isLoading}
        editDisabled={isOptionFormDisabled}
        // TODO: OptionValues are nested and their deletion should be prevented only if whole Option is not editable
        deleteDisabled={isOptionFormDisabled}
      />
    </OptionFormStyled>
  )
}
