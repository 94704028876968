import * as React from 'react'
import { useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import { hasValue, sameArrayValues } from '@digital-magic/ts-common-utils'
import { emptyTranslatedString } from '@api/endpoints/utils'
import {
  CreateOptionValueRequest,
  OptionId,
  OptionValueId,
  useCreateOptionValue,
  useCreateOptionValueImage,
  useGetOptionValues,
  useUpdateOptionValue
} from '@api/endpoints/buildings/options'
import {
  TranslatedFileList,
  convertTranslatedFileListToSingleFile,
  undefinedTranslatedFileList,
  useFileUpload,
  useTranslatedFileUpload
} from '@api/endpoints/files'
import { useAdminDefaultErrorHandler } from '@hooks/useAdminDefaultErrorHandler'
import { Box } from '@mui/material'
import { Button } from '@controls/Button'
import { Form, useFormTyped } from '@controls/Form'
import { Text } from '@controls/Text'
import { OptionValueItem } from './OptionValueItem'
import { OptionValuesFormStyled } from './styles'

type OptionValuesFormProps = {
  optionId: OptionId
  isParentLoading: boolean
  editDisabled: boolean
  deleteDisabled: boolean
}

const OptionValuesFormValues = z.object({
  optionValues: CreateOptionValueRequest.extend({
    optionValueId: OptionValueId.optional(),
    document: TranslatedFileList.partial(),
    images: z.instanceof(FileList).optional()
  })
    .refine(
      (obj) =>
        sameArrayValues<boolean>([
          hasValue(obj.document?.eng),
          hasValue(obj.document?.est),
          hasValue(obj.document?.rus)
        ]),
      {
        path: ['document', 'eng'],
        // TODO: Add translation
        message: 'Document file must be specified for either all languages or none!'
      }
    )
    .array()
})
export type OptionValuesFormValues = z.infer<typeof OptionValuesFormValues>

export const OptionValuesForm = ({
  optionId,
  isParentLoading,
  editDisabled,
  deleteDisabled
}: OptionValuesFormProps): React.ReactElement => {
  const { t } = useTranslation()
  const defaultErrorHandler = useAdminDefaultErrorHandler()

  const uploadFile = useFileUpload({ onError: defaultErrorHandler })
  const getOptionValues = useGetOptionValues(optionId)
  const createOptionValue = useCreateOptionValue(optionId, { onError: defaultErrorHandler })
  const updateOptionValue = useUpdateOptionValue(optionId, { onError: defaultErrorHandler })

  const uploadOptionValueImage = useCreateOptionValueImage(optionId, { onError: defaultErrorHandler })

  const isLoading =
    isParentLoading ||
    updateOptionValue.isLoading ||
    createOptionValue.isLoading ||
    uploadOptionValueImage.isLoading ||
    uploadFile.isLoading

  const optionValuesData = React.useMemo(
    () => getOptionValues.data?.concat()?.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime()),
    [getOptionValues.data]
  )

  const uploadImages = async (images: FileList, optionValueId: OptionValueId): Promise<void> => {
    // eslint-disable-next-line functional/no-loop-statements
    for (const image of images) {
      const uploadResp = await uploadFile.mutateAsync(image)
      await uploadOptionValueImage.mutateAsync({ fileId: uploadResp.id, optionValueId })
    }
  }

  const uploadTranslatedDocument = useTranslatedFileUpload(uploadFile)

  const onSubmit = async ({ optionValues }: OptionValuesFormValues): Promise<void> => {
    if (optionId) {
      // eslint-disable-next-line functional/no-loop-statements
      for (const { images, optionValueId, documentId: oldDocId, document, ...optionValue } of optionValues) {
        const doc = convertTranslatedFileListToSingleFile(document)
        const documentId = hasValue(doc) ? await uploadTranslatedDocument(doc) : oldDocId
        if (!hasValue(optionValueId)) {
          const { id } = await createOptionValue.mutateAsync({ ...optionValue, documentId })

          if (images) {
            await uploadImages(images, id)
          }
        } else {
          await updateOptionValue.mutateAsync({ ...optionValue, id: optionValueId, documentId })
          if (images) {
            await uploadImages(images, optionValueId)
          }
        }
      }
    }
  }

  const form = useFormTyped({
    resolver: OptionValuesFormValues,
    onSubmit
  })

  const optionValues = useFieldArray({ control: form.control, name: 'optionValues' })

  const onAddValue = (): void => {
    optionValues.append({
      optionValueId: undefined,
      name: emptyTranslatedString(),
      document: undefinedTranslatedFileList()
    })
  }

  React.useEffect(() => {
    if (hasValue(optionValuesData)) {
      form.reset({
        optionValues: optionValuesData.map((value) =>
          value.description === undefined
            ? {
                optionValueId: value.id,
                name: value.name,
                documentId: value.documentId,
                document: undefinedTranslatedFileList()
              }
            : {
                optionValueId: value.id,
                name: value.name,
                description: value.description,
                documentId: value.documentId,
                document: undefinedTranslatedFileList()
              }
        )
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionValuesData])

  return (
    <Form f={form}>
      <OptionValuesFormStyled>
        <Text size="size-24" weight="regular" mb={2} component="div">
          {t('pages.admin.option_values.title')}
        </Text>
        {optionValues.fields.length > 0 && (
          <Box display="flex" flexDirection="column" width="100%" rowGap={4} mb={4}>
            {optionValues.fields.map((f, i) => (
              <OptionValueItem
                key={f.id}
                index={i}
                array={optionValues}
                optionId={optionId}
                isParentLoading={isLoading}
                editDisabled={editDisabled}
                deleteDisabled={deleteDisabled}
              />
            ))}
          </Box>
        )}
        <Button fullWidth type="button" disabled={isLoading || editDisabled} onClick={onAddValue} color="brown">
          {'+ ' + t('pages.admin.option_values.buttons.add')}
        </Button>

        <Box mt={4}>
          <Button disabled={isLoading || editDisabled} type="submit">
            {t('pages.admin.option_values.buttons.save')}
          </Button>
        </Box>
      </OptionValuesFormStyled>
    </Form>
  )
}
