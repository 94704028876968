import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { isApiError } from '@digital-magic/react-common/lib/api'
import { Handler } from '@digital-magic/ts-common-utils'
import { routes } from '@constants/routes'
import { RequestErrorHandler } from '@api/types'
import { IdObject } from '@api/endpoints/types'
import { CreateOptionRequest, useCreateOption } from '@api/endpoints/buildings/options'
import { useAdminDefaultErrorHandler } from '@hooks/useAdminDefaultErrorHandler'
import { useSnackbar } from 'notistack'
import { useFormTyped } from '@controls/Form'
import { OptionForm } from './OptionForm'
import { OptionFormStyled } from './styles'

export const NewOptionForm: React.FC = () => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const defaultErrorHandler = useAdminDefaultErrorHandler()

  const onCreateOptionSuccess: Handler<IdObject> = ({ id }) => {
    navigate(generatePath(routes.Admin.OptionEdit, { optionId: id }), { replace: true })
  }
  const onCreateOptionError: RequestErrorHandler = (e) => {
    if (isApiError(e) && e.code === 'AlreadyExistsError') {
      snackbar.enqueueSnackbar(t('pages.admin.option.errors.already_exists'))
    } else {
      defaultErrorHandler(e)
    }
  }

  const createOption = useCreateOption({ onError: onCreateOptionError, onSuccess: onCreateOptionSuccess })
  const isLoading = createOption.isLoading

  const onSubmit = (values: CreateOptionRequest): void => {
    createOption.mutate(values)
  }

  const form = useFormTyped({
    resolver: CreateOptionRequest,
    onSubmit,
    defaultValues: {
      multiValue: false
    }
  })

  return (
    <OptionFormStyled>
      <h1>{t('pages.admin.option.new.title')}</h1>
      <OptionForm isOptionFormDisabled={false} isDeleteDisabled={false} isLoading={isLoading} f={form} />
    </OptionFormStyled>
  )
}
