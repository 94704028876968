import * as React from 'react'
import { UseFieldArrayReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { isApiError } from '@digital-magic/react-common/lib/api'
import { hasValue, reorderArray } from '@digital-magic/ts-common-utils'
import { getTranslatedField } from '@utils/language-utils'
import { RequestErrorHandler } from '@api/types'
import { Language, TranslatedString } from '@api/endpoints/types'
import { emptyTranslatedString } from '@api/endpoints/utils'
import {
  OptionId,
  useDeleteOptionValue,
  useDeleteOptionValueImage,
  useGetOptionValueImages,
  useUpdateOptionValueImagesOrder
} from '@api/endpoints/buildings/options'
import { getSmallThumbnailId } from '@api/endpoints/files'
import { useFilesDownload } from '@api/endpoints/files/api'
import { useAdminDefaultErrorHandler } from '@hooks/useAdminDefaultErrorHandler'
import { useSnackbar } from 'notistack'
import { Delete } from '@mui/icons-material'
import { Box } from '@mui/material'
import { DropResult } from '@hello-pangea/dnd'
import { ButtonWithConfirmation } from '@controls/Button/ButtonWithConfirmation'
import { Checkbox } from '@controls/Checkbox'
import { DraggableImageList } from '@controls/DraggableImageList'
import { FormTextField, useFormContextTyped } from '@controls/Form'
import { FormFileInput } from '@controls/Form/FormFileInput'
import { UploadTranslatedFiles } from '@controls/TranslatedFile'
import { DownloadTranslatedFiles } from '@controls/TranslatedFile/DownloadTranslatedFiles'
import { OptionValuesFormValues } from '../OptionValuesForm'
import { OptionValueItemStyled } from './OptionValueItem.styles'

type OptionValueItemProps = {
  index: number
  array: UseFieldArrayReturn<OptionValuesFormValues, 'optionValues'>
  optionId: OptionId
  isParentLoading: boolean
  editDisabled: boolean
  deleteDisabled: boolean
}

export const OptionValueItem: React.FC<OptionValueItemProps> = ({
  index,
  array,
  optionId,
  isParentLoading,
  editDisabled,
  deleteDisabled
}) => {
  const { t } = useTranslation()
  const [isDeleting, setIsDeleting] = React.useState(false)
  const form = useFormContextTyped<OptionValuesFormValues>()
  const field = array.fields[index]
  const snackbar = useSnackbar()
  const defaultErrorHandler = useAdminDefaultErrorHandler()

  const onDeleteOptionValueError: RequestErrorHandler = (e): void => {
    if (isApiError(e) && e.code === 'ConstraintViolationError') {
      snackbar.enqueueSnackbar(t('pages.admin.option_value.errors.delete_constraint'))
    } else {
      defaultErrorHandler(e)
    }
    setIsDeleting(false)
  }

  // TODO: Refactor these `?? ''` statements
  const deleteOptionValue = useDeleteOptionValue(optionId, field.optionValueId ?? '', {
    onError: onDeleteOptionValueError
  })
  const deleteOptionValueImage = useDeleteOptionValueImage(optionId, field.optionValueId ?? '', {
    onError: defaultErrorHandler
  })
  const updateOptionValueImagesOrder = useUpdateOptionValueImagesOrder(optionId, field.optionValueId ?? '', {
    onError: defaultErrorHandler
  })

  const getOptionValueImages = useGetOptionValueImages(optionId, field.optionValueId ?? '', {
    onError: defaultErrorHandler,
    enabled: hasValue(field.optionValueId) && !isDeleting
  })

  const optionValueImages = React.useMemo(
    () => getOptionValueImages.data?.concat()?.sort((a, b) => a.orderNumber - b.orderNumber),
    [getOptionValueImages.data]
  )

  const downloadFiles = useFilesDownload((optionValueImages ?? []).map(getSmallThumbnailId), {
    onError: defaultErrorHandler,
    enabled: hasValue(optionValueImages) && !isDeleting
  })

  const isLoading =
    isParentLoading ||
    downloadFiles.isFetching ||
    deleteOptionValueImage.isLoading ||
    deleteOptionValue.isLoading ||
    updateOptionValueImagesOrder.isLoading ||
    getOptionValueImages.isFetching ||
    downloadFiles.isFetching

  const imageUrls = React.useMemo((): ReadonlyArray<string> => {
    return downloadFiles.data?.map((image) => URL.createObjectURL(image)) ?? []
  }, [downloadFiles.data])

  const onDeleteImage =
    (index: number) =>
    (confirmResult: boolean): void => {
      const fileId = optionValueImages?.[index].id

      if (fileId && optionId && field.optionValueId && confirmResult) {
        deleteOptionValueImage.mutate(fileId)
      }
    }

  const onDelete = (confirmResult: boolean): void => {
    if (confirmResult) {
      setIsDeleting(true)
      if (field.optionValueId) {
        deleteOptionValue.mutate()
      } else {
        array.remove(index)
      }
    }
  }

  const setDescription = React.useCallback(
    (v?: TranslatedString) => array.update(index, { ...field, description: v }),
    [array, field, index]
  )

  const setIncludeDescription = React.useCallback(
    (value: boolean) => (value ? setDescription(emptyTranslatedString()) : setDescription(undefined)),
    [setDescription]
  )

  const clearDocumentId = React.useCallback(
    () =>
      array.update(index, {
        ...field,
        documentId: undefined
      }),
    [array, field, index]
  )

  const clearUploadField = (lang: Language): void => {
    form.resetField(`optionValues.${index}.document.${getTranslatedField(lang)}`, {
      defaultValue: undefined
    })
  }

  const isFormDisabled = hasValue(field.optionValueId) && editDisabled

  const onDragEnd = (result: DropResult): void => {
    if (
      hasValue(optionId) &&
      hasValue(field.optionValueId) &&
      hasValue(optionValueImages) &&
      hasValue(result.destination) &&
      hasValue(result.source.index) &&
      result.destination.index !== result.source.index
    ) {
      const reordered = reorderArray(optionValueImages, result.source.index, result.destination.index).map((v, i) => ({
        ...v,
        orderNumber: i
      }))
      updateOptionValueImagesOrder.mutate({
        items: reordered.map((v) => ({
          item: v.id,
          index: v.orderNumber
        }))
      })
    }
  }

  return (
    <OptionValueItemStyled>
      <Box display="flex" alignItems="flex-start" columnGap={2}>
        <ButtonWithConfirmation
          color="error"
          onConfirmResult={onDelete}
          disabled={isLoading || deleteDisabled}
          confirmTitle={t('global.consent.delete.title')}
          confirmMessage={t('global.consent.delete.message')}
        >
          <Delete />
        </ButtonWithConfirmation>
        <Box display="flex" flexDirection="column" rowGap={2} flex={1} maxWidth={1000}>
          <FormTextField
            disabled={isLoading || isFormDisabled}
            label={`${t('pages.admin.option_value.form.field.name')} ${t('app.languages.en')}`}
            name={form.names.optionValues[index].name.eng}
          />
          <FormTextField
            disabled={isLoading || isFormDisabled}
            label={`${t('pages.admin.option_value.form.field.name')} ${t('app.languages.et')}`}
            name={form.names.optionValues[index].name.est}
          />
          <FormTextField
            disabled={isLoading || isFormDisabled}
            label={`${t('pages.admin.option_value.form.field.name')} ${t('app.languages.ru')}`}
            name={form.names.optionValues[index].name.rus}
          />
          <Checkbox
            disabled={isLoading || isFormDisabled}
            label={t('pages.admin.option_value.form.field.include_description')}
            checked={hasValue(field.description)}
            onChange={(e) => setIncludeDescription(e.target.checked)}
          />
          {hasValue(field.description) && [
            <FormTextField
              key="en"
              disabled={isLoading || isFormDisabled}
              label={`${t('pages.admin.option_value.form.field.description')} ${t('app.languages.en')}`}
              name={form.names.optionValues[index].description.eng}
              multiline
              rows={3}
            />,
            <FormTextField
              key="et"
              disabled={isLoading || isFormDisabled}
              label={`${t('pages.admin.option_value.form.field.description')} ${t('app.languages.et')}`}
              name={form.names.optionValues[index].description.est}
              multiline
              rows={3}
            />,
            <FormTextField
              key="ru"
              disabled={isLoading || isFormDisabled}
              label={`${t('pages.admin.option_value.form.field.description')} ${t('app.languages.ru')}`}
              name={form.names.optionValues[index].description.rus}
              multiline
              rows={3}
            />
          ]}
          {hasValue(field.documentId) ? (
            <DownloadTranslatedFiles
              title={t('pages.admin.option_value.form.field.document') + ':'}
              translatedFileId={field.documentId}
              deleteDisabled={isLoading || isFormDisabled}
              onDelete={clearDocumentId}
            />
          ) : (
            <UploadTranslatedFiles
              title={t('pages.admin.option_value.form.field.document') + ':'}
              nameEng={form.names.optionValues[index].document.eng}
              nameEst={form.names.optionValues[index].document.est}
              nameRus={form.names.optionValues[index].document.rus}
              disabled={isLoading || isFormDisabled}
              accept="application/pdf"
              onDelete={clearUploadField}
            />
          )}
          <Box display="flex" flexDirection="column" rowGap={2}>
            {imageUrls.length > 0 && (
              <DraggableImageList
                title={t('pages.admin.option_value.images.title')}
                imageUrls={imageUrls}
                disabled={isLoading || deleteDisabled}
                onDragEnd={onDragEnd}
                onDeleteImage={onDeleteImage}
              />
            )}
            <Box mt={2} mb={2} borderTop={1} color="#B5B5B5" />
            <Box display="flex" alignItems="center" columnGap={2}>
              <span>{t('pages.admin.option_value.images.upload.title')}:</span>
              <FormFileInput
                accept="image/*"
                multiple
                name={form.names.optionValues[index].images}
                disabled={isLoading || isFormDisabled}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </OptionValueItemStyled>
  )
}
