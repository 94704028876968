import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { UseFormTypedResult } from '@digital-magic/react-common/lib/components/controls/form'
import { CreateOptionRequest } from '@api/endpoints/buildings/options'
import { Alert, Box } from '@mui/material'
import { Button } from '@controls/Button'
import { Form, FormCheckbox, FormTextField } from '@controls/Form'

type Props = Readonly<{
  f: UseFormTypedResult<CreateOptionRequest>
  isOptionFormDisabled: boolean
  isDeleteDisabled: boolean
  isLoading: boolean
  formButtons?: React.ReactNode | undefined
}>

export const OptionForm: React.FC<Props> = ({ f, isOptionFormDisabled, isDeleteDisabled, isLoading, formButtons }) => {
  const { t } = useTranslation()

  return (
    <Form f={f}>
      <Box display="flex" flexDirection="column" width="100%" rowGap={2} mb={4}>
        {(isOptionFormDisabled || isDeleteDisabled) && (
          <Alert severity="warning">
            {isOptionFormDisabled
              ? t('pages.admin.option.messages.edit_restricted')
              : t('pages.admin.option.messages.delete_restricted')}
          </Alert>
        )}
        <FormTextField
          disabled={isLoading || isOptionFormDisabled}
          name={f.names.code}
          label={t('pages.admin.option.form.fields.code')}
        />
        <FormTextField
          disabled={isLoading || isOptionFormDisabled}
          name={f.names.name.eng}
          label={`${t('pages.admin.option.form.fields.name')} ${t('app.languages.en')}`}
        />
        <FormTextField
          disabled={isLoading || isOptionFormDisabled}
          name={f.names.name.est}
          label={`${t('pages.admin.option.form.fields.name')} ${t('app.languages.et')}`}
        />
        <FormTextField
          disabled={isLoading || isOptionFormDisabled}
          name={f.names.name.rus}
          label={`${t('pages.admin.option.form.fields.name')} ${t('app.languages.ru')}`}
        />
        <FormCheckbox
          disabled={isLoading || isOptionFormDisabled}
          name={f.names.multiValue}
          label={t('pages.admin.option.form.fields.multi_value')}
        />
      </Box>
      {!isOptionFormDisabled && (
        <Box display="flex" columnGap={2} mt={2}>
          <Button disabled={isLoading || isOptionFormDisabled} type="submit">
            {t('global.buttons.submit')}
          </Button>
          {formButtons}
        </Box>
      )}
    </Form>
  )
}
