import * as React from 'react'
import { useParams } from 'react-router-dom'
import { hasValue } from '@digital-magic/ts-common-utils'
import { OptionId } from '@api/endpoints/buildings/options'
import { EditOptionForm } from './EditOptionForm'
import { NewOptionForm } from './NewOptionForm'

type Params = {
  optionId: OptionId
}

const ManageOption: React.FC = () => {
  const params = useParams<Params>()

  return hasValue(params.optionId) ? <EditOptionForm optionId={params.optionId} /> : <NewOptionForm />
}

export default ManageOption
